var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height",class:{
    'grey darken-4': !_vm.$vuetify.theme.dark,
    white: _vm.$vuetify.theme.dark,
  },attrs:{"fluid":""}},[_c('v-card',{staticClass:"elevation-0 transparent"},[_c('v-card-text',{class:{
        'white--text': !_vm.$vuetify.theme.dark,
        'black--text': _vm.$vuetify.theme.dark,
      },attrs:{"text-center":""}},[_c('div',{staticClass:"pb-8"},[(_vm.$vuetify.theme.dark)?_c('v-img',{attrs:{"height":"50","contain":"","src":require("@/assets/images/logo/logo-dark.png"),"alt":""}}):_c('v-img',{attrs:{"height":"50","contain":"","src":require("@/assets/images/logo/logo-light.png"),"alt":""}})],1),_c('div',{staticClass:"d-flex flex-wrap justify-center gap-12"},[_c('div',{staticStyle:{"max-width":"320px"}},[_c('router-link',{class:{
              'grey--text text--lighten-2': !_vm.$vuetify.theme.dark,
              'black--text': _vm.$vuetify.theme.dark,
            },attrs:{"to":"/terms/contract"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-start"},[_c('div',{staticClass:"d-flex pb-3 justify-space-between align-center"},[_c('h5',{class:{
                      'white--text': !_vm.$vuetify.theme.dark,
                      'black--text': _vm.$vuetify.theme.dark,
                    }},[_vm._v(" Termos & Condições ")]),_c('v-icon',{attrs:{"medium":"","color":_vm.$vuetify.theme.dark ? 'black' : 'white'}},[_vm._v("mdi-arrow-right")])],1),_c('p',[_vm._v(" Leia nossos termos para entender os direitos e responsabilidades. Ao utilizar nosso serviço, você concorda com estas condições. ")])])])])],1),_c('div',{staticStyle:{"max-width":"320px"}},[_c('router-link',{class:{
              'grey--text text--lighten-2': !_vm.$vuetify.theme.dark,
              'black--text': _vm.$vuetify.theme.dark,
            },attrs:{"to":"/terms/privacy"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-start"},[_c('div',{staticClass:"d-flex pb-3 justify-space-between align-center"},[_c('h5',{class:{
                      'white--text': !_vm.$vuetify.theme.dark,
                      'black--text': _vm.$vuetify.theme.dark,
                    }},[_vm._v(" Política de Privacidade ")]),_c('v-icon',{attrs:{"medium":"","color":_vm.$vuetify.theme.dark ? 'black' : 'white'}},[_vm._v("mdi-arrow-right")])],1),_c('p',[_vm._v(" Priorizamos sua privacidade e a proteção de seus dados. Leia nossa política para saber como suas informações são utilizadas. ")])])])])],1)]),_c('div',{staticClass:"d-flex justify-center pt-7 pb-4"},[_c('p',{class:{
            'grey--text text--lighten-2': !_vm.$vuetify.theme.dark,
            'black--text': _vm.$vuetify.theme.dark,
          }},[_vm._v(" "+_vm._s(_vm.texts.footerDescription)+" ")]),_c('div',{staticClass:"d-flex gap-3 align-center pb-2"},[_c('p',{class:{
              'grey--text font-weight-bold text-15 text--lighten-2':
                !_vm.$vuetify.theme.dark,
              'black--text font-weight-bold text-15': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.legalInfo.fantasyName)+" ")]),_c('span',{class:{
              'grey--text text--lighten-2 text-12': !_vm.$vuetify.theme.dark,
              'black--text text-12': _vm.$vuetify.theme.dark,
            }},[_vm._v(" "+_vm._s(_vm.legalInfo.document)+" ")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }