<template>
  <v-container
    :class="{
      'grey darken-4': !$vuetify.theme.dark,
      white: $vuetify.theme.dark,
    }"
    class="fill-height"
    fluid
  >
    <v-card class="elevation-0 transparent">
      <v-card-text
        :class="{
          'white--text': !$vuetify.theme.dark,
          'black--text': $vuetify.theme.dark,
        }"
        text-center
      >
        <div class="pb-8">
          <v-img
            v-if="$vuetify.theme.dark"
            height="50"
            contain
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <v-img
            v-else
            height="50"
            contain
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
        </div>
        <div class="d-flex flex-wrap justify-center gap-12">
          <div style="max-width: 320px">
            <router-link
              to="/terms/contract"
              :class="{
                'grey--text text--lighten-2': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <div class="d-flex">
                <div class="text-start">
                  <div class="d-flex pb-3 justify-space-between align-center">
                    <h5
                      :class="{
                        'white--text': !$vuetify.theme.dark,
                        'black--text': $vuetify.theme.dark,
                      }"
                    >
                      Termos & Condições
                    </h5>
                    <v-icon
                      medium
                      :color="$vuetify.theme.dark ? 'black' : 'white'"
                      >mdi-arrow-right</v-icon
                    >
                  </div>
                  <p>
                    Leia nossos termos para entender os direitos e
                    responsabilidades. Ao utilizar nosso serviço, você concorda
                    com estas condições.
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div style="max-width: 320px">
            <router-link
              to="/terms/privacy"
              :class="{
                'grey--text text--lighten-2': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <div class="d-flex">
                <div class="text-start">
                  <div class="d-flex pb-3 justify-space-between align-center">
                    <h5
                      :class="{
                        'white--text': !$vuetify.theme.dark,
                        'black--text': $vuetify.theme.dark,
                      }"
                    >
                      Política de Privacidade
                    </h5>
                    <v-icon
                      medium
                      :color="$vuetify.theme.dark ? 'black' : 'white'"
                      >mdi-arrow-right</v-icon
                    >
                  </div>
                  <p>
                    Priorizamos sua privacidade e a proteção de seus dados. Leia
                    nossa política para saber como suas informações são
                    utilizadas.
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="d-flex justify-center pt-7 pb-4">
          <p
            :class="{
              'grey--text text--lighten-2': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            {{ texts.footerDescription }}
          </p>
          <div class="d-flex gap-3 align-center pb-2">
            <p
              :class="{
                'grey--text font-weight-bold text-15 text--lighten-2':
                  !$vuetify.theme.dark,
                'black--text font-weight-bold text-15': $vuetify.theme.dark,
              }"
            >
              {{ legalInfo.fantasyName }}
            </p>
            <span
              :class="{
                'grey--text text--lighten-2 text-12': !$vuetify.theme.dark,
                'black--text text-12': $vuetify.theme.dark,
              }"
            >
              {{ legalInfo.document }}
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { texts, legalInfo } from "@/themeConfig.js";
export default {
  name: "OpenAIComponent",
  data() {
    return {
      texts,
      legalInfo,
    };
  },
  methods: {
    goToContract() {
      this.$router.push({ name: "terms.contract" });
    },
    goToPrivacy() {
      this.$router.push({ name: "terms.privacy" });
    },
  },
};
</script>

<style scoped>
.transparent {
  background-color: transparent !important;
}

.fill-height {
  border-radius: 10px;
}
</style>
